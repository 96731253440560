import React, { useEffect, useState } from "react";
import leftArrow from "../../assets/img/left-arrow.png";
import rigthArrow from "../../assets/img/right-arrow.png";
import { useNavigate } from "react-router-dom";
import addIcon from "../../assets/img/addUser.png";
import deleteIcon from "../../assets/img/delete.svg";
// import searchIcon from "../../common/assets/img/search.png";
import searchIcon from "../../assets/img/search.png";
import Images from "../../assets";
import axios from "axios";
import logo from "../../assets/img/timber_logo.png";
import { useMutation } from "react-query";
import { useQuery } from "react-query";
import PopUpModal from "../popupmodal/PopUpModal";
import moment from "moment/moment";

const DatamanagementTable = () => {
  const tableData = [
    {
      id: 1,
      select: "checkbox",
      bfId: "2k005",
      name: "sabari",
      currentForm: "P&L",
      pendingForm: "7 FORMS",
      formStatus: "Pending",
    },

    {
      id: 2,
      select: "checkbox",
      bfId: "2k005",
      name: "sabari",
      currentForm: "P&L",
      pendingForm: "7 FORMS",
      formStatus: "Pending",
    },

    {
      id: 3,
      select: "checkbox",
      bfId: "2k005",
      name: "sabari",
      currentForm: "P&L",
      pendingForm: "7 FORMS",
      formStatus: "Pending",
    },

    {
      id: 4,
      select: "checkbox",
      bfId: "2k005",
      name: "sabari",
      currentForm: "P&L",
      pendingForm: "7 FORMS",
      formStatus: "Pending",
    },

    {
      id: 5,
      select: "checkbox",
      bfId: "2k005",
      name: "prasanth",
      currentForm: "sblc",
      pendingForm: "7 FORMS",
      formStatus: "Pending",
    },
    {
      id: 6,
      select: "checkbox",
      bfId: "2k005",
      name: "sabari",
      currentForm: "sblc",
      pendingForm: "7 FORMS",
      formStatus: "Pending",
    },
    {
      id: 7,
      select: "checkbox",
      bfId: "2k005",
      name: "sabari",
      currentForm: "P&L",
      pendingForm: "7 FORMS",
      formStatus: "Pending",
    },
    {
      id: 8,
      select: "checkbox",
      bfId: "2k005",
      name: "sabari",
      currentForm: "P&L",
      pendingForm: "7 FORMS",
      formStatus: "Completed",
    },
    {
      id: 9,
      select: "checkbox",
      bfId: "2k005",
      name: "sabari",
      currentForm: "P&L",
      pendingForm: "7 FORMS",
      formStatus: "Pending",
    },
    {
      id: 10,
      select: "checkbox",
      bfId: "2k005",
      name: "azarudhin",
      currentForm: "P&L",
      pendingForm: "7 FORMS",
      formStatus: "Completed",
    },
    {
      id: 11,
      select: "checkbox",
      bfId: "2k005",
      name: "ranjith",
      currentForm: "P&L",
      pendingForm: "5 FORMS",
      formStatus: "Completed",
    },
    {
      id: 12,
      select: "checkbox",
      bfId: "2k005",
      name: "vignesh",
      currentForm: "P&L",
      pendingForm: "7 FORMS",
      formStatus: "Pending",
    },
    {
      id: 13,
      select: "checkbox",
      bfId: "2k005",
      name: "vignesh",
      currentForm: "P&L",
      pendingForm: "7 FORMS",
      formStatus: "Pending",
    },
  ];
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);
  const [searchTerm, setSearchTerm] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState([]); // Array of IDs to delete
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [itemId, setItemId] = useState(null);
  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };
  // Filter data based on search term

  // Fetch dataManagement details
  const fetchDataManagement = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/invoices`
    );
  };

  const { data, isLoading, isError, refetch, isFetched } = useQuery(
    "data",
    fetchDataManagement
  );
  let details = data && data?.data;
  console.log("DETAILS_deleteIndex", deleteIndex, data);
  const getBlNumbersById = (deleteIndex) => {
    return data && data?.data
      .filter((item) => deleteIndex.includes(item.id)) // Check if item's id is in the provided ids array
      .map((item) => item.blNo); // Extract blNo values from the matching objects
  };
  const blNumbers = getBlNumbersById(deleteIndex);
  console.log('getBlNumbersById', blNumbers); // Output the result
  // Example usage
  // useEffect(() => {
  //    // Change the ID as needed
  // }, []);
  const filteredData = details?.filter((item) => {
    return (
      item?.blNo?.toLowerCase().startsWith(searchTerm?.toLowerCase()) ||
      item?.exporter?.toLowerCase().startsWith(searchTerm?.toLowerCase()) ||
      item?.fileName?.toLowerCase().startsWith(searchTerm?.toLowerCase())
    );
  });
  // Calculate pagination values
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  useEffect(() => {
    // Reset to page 1 when search term changes
    setCurrentPage(1);
  }, [searchTerm]);
  // Check if pagination buttons should be disabled
  const isPrevDisabled = currentPage === 1;
  const isNextDisabled = currentPage === totalPages || totalPages === 0;
  const handlePrevPage = () => {
    if (!isPrevDisabled) setCurrentPage(currentPage - 1);
  };

  const handleCheckboxChange = (id) => {
    if (deleteIndex.includes(id)) {
      // If the ID is already in the deleteIndex array, remove it
      setDeleteIndex(deleteIndex.filter((item) => item !== id));
    } else {
      // Otherwise, add the ID to the deleteIndex array
      setDeleteIndex([...deleteIndex, id]);
    }
  };

  const handleNextPage = () => {
    if (!isNextDisabled) setCurrentPage(currentPage + 1);
  };

  const HandlerDelete = async () => {
    try {
      // Ensure deleteIndex is used correctly
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST}/invoices/delete`,
        {
          data: deleteIndex, // Assuming the backend expects an array of IDs in the body
        }
      );
      const responses = await axios.delete(
        `https://api.2kvirtualworld.com/bestTimbers/copydocuments/deleteByBlNos`,
        {
          data: blNumbers, // Assuming the backend expects an array of IDs in the body
        }
      );

      console.log("Delete response:", response.data);

      // Refetch data
      refetch();
      // Close popups and reset states
      setDeletePopUp(true);
      setPopupOpen(false);
      setDeleteIndex([]); // Clear the deleteIndex
      return response.data;
    } catch (error) {
      console.log("Error", error);
    }
  };
  // COPY DOC API GET START
  const [copyid, setcopyId] = useState()
  const [isheat, setIsheat] = useState(false)
  const fetchcopydoc = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/copydocuments/${copyid}`
    );
  };
  const Copydoc = useQuery(
    ["data", copyid],
    fetchcopydoc, {
    enabled: isheat,
    onSuccess: () => {
      setIsheat(false)
    },
    onError: () => {
      setIsheat(false)

    }

  }
  );
  // COPY DOC API GET END

  const HandlerEdit = async (val) => {
    setIsheat(true)
    console.log("COOPY ID", val)

    setcopyId(val?.blNo)
    // }
    if (!isheat) {

      sessionStorage.setItem("copydoc", Copydoc?.data && Copydoc?.data?.data?.copydocuments ? JSON.stringify(Copydoc?.data && Copydoc?.data?.data?.copydocuments) : [])
      sessionStorage.setItem(
        "formdata", JSON.stringify(val)
      );
      navigate("/dataEntryForm", { state: val });
    }


  }
  console.log("COOPY_ID_Copydoc", isLoading)
  return (
    <>
      <div className="headerTimeLine  px-3 py-3">
        <div className="px-5 d-flex justify-content-between">
          <div className="d-flex search-block">
            <input
              type="search"
              className="table-search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div style={{ width: "20px", height: "20px" }}>
              <img src={searchIcon} style={{ width: "100%", height: "100%" }} />
            </div>
          </div>
          <div
            className="d-flex gap-2 align-items-center"
            style={{ width: "35%" }}
          >
            <div
              className="add-user-btn-block"
              onClick={() => {
                navigate("/dataEntryForm");
              }}

            >
              <button className="new-user-btn">NEW DATA ENTRY</button>
              <div style={{ width: "20px", height: "20px" }}>
                <img src={addIcon} className="img-icon"></img>
              </div>
            </div>
            {deleteIndex.length !== 0 ?

              <div
                className="add-user-btn-block"
                onClick={() => {
                  setPopupOpen(true);
                }}
              >
                <button className="new-user-btn">DELETE</button>
                <div style={{ width: "20px", height: "20px" }}>
                  <img src={deleteIcon} className="img-icon"></img>
                </div>
              </div>
              : <div
                className="add-user-btn-block"
                style={{
                  background: "transparent",
                  boxShadow: "none"
                }}

              ></div>
            }
          </div>
        </div>
      </div>
      <div className="px-5  mt-3">
        <div className="view-table-block py-3 px-2">
          <table className="view-user-table">
            <thead>
              <tr>
                <td className="view-user-table-head --first-head">SELECT</td>
                <td className="view-user-table-head">BL NO</td>
                <td className="view-user-table-head">File Name</td>
                <td className="view-user-table-head">EXPORTER</td>
                <td className="view-user-table-head">INVOICE NO</td>
                <td className="view-user-table-head">BE NO</td>
                <td className="view-user-table-head">BG NUMBER & DATE</td>
                <td className="view-user-table-head --last-head">EDIT</td>
              </tr>
            </thead>
            <tbody className={isLoading ? "loading_body" : ''}>
              {isLoading ? (
                <tr style={{ height: "180PX", position: "relative" }}>
                  <div style={{ position: 'relative', width: '300px', height: 'auto' }}>
                    <div className='loading' />
                    <img alt='logo'
                      src={Images.logo}
                      // alt={alt}
                      // onLoad={() => setLoading(false)}
                      style={{ opacity: 0.7, display: 'block', width: '100%', height: 'auto', position: 'absolute', top: 0, left: 0 }}
                    />
                  </div>
                </tr>
              )


                : isError ? (
                  <tr>
                    <p
                      style={{
                        color: "#000",
                        fontSize: "18px",
                        fontWeight: 800,
                      }}
                    >
                      No Network...
                    </p>
                  </tr>
                ) :
                  currentItems?.length > 0 ? (
                    currentItems?.map((val, i) => {
                      return (
                        <>
                          <tr key={i} className="view-user-table-row">
                            <td className="view-user-table-data">
                              <input
                                id="myCheckbox"
                                type="checkbox"
                                checked={deleteIndex.includes(val.id)} // Check if the ID is in the deleteIndex array
                                onChange={() => handleCheckboxChange(val.id)} // Use a handler to manage the checkbox state
                              />
                            </td>
                            <td className="view-user-table-data">{val?.blNo}</td>
                            <td className="view-user-table-data">{val?.fileName}</td>
                            <td className="view-user-table-data">
                              {val?.exporter}
                            </td>
                            <td className="view-user-table-data">
                              {val?.invoiceNo}
                            </td>
                            <td className="view-user-table-data">
                              {val?.beNo}
                            </td>
                            <td className="view-user-table-data">
                              {val?.bgNumber}/{moment(val?.bgdate).format("DD-MM-YYYY")}
                            </td>
                            <td
                              className="view-user-table-data"
                              onClick={() => HandlerEdit(val)}
                            >
                              <img
                                src={Images?.editpencil}
                                style={{ width: "50px", height: "25px" }}
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="7">No results found</td>
                    </tr>
                  )}
            </tbody>
          </table>
          <div className="d-flex justify-content-between pt-2 px-1">
            <button
              onClick={handlePrevPage}
              disabled={isPrevDisabled}
              className="arrow-btn"
            >
              &lt;
            </button>
            <button
              onClick={handleNextPage}
              disabled={isNextDisabled}
              className="arrow-btn"
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
      <PopUpModal isOpen={isPopupOpen} onClose={togglePopup}>
        <div className="img-block">
          <div style={{ width: "40%", height: "60%" }} className="mx-2 pt-2">
            <img
              src={logo}
              style={{ width: "100%", height: "100%" }}
              alt="logo"
            />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center py-1">
          <h6 className="popup-content mb-3">
            Are you sure you want to delete this detail?
          </h6>
          <div className="d-flex justify-content-center gap-5 mb-3">
            <button
              className="user-delete-button"
              onClick={() => {
                HandlerDelete();
                // Optionally pass itemId here if needed
              }}
            >
              OK
            </button>
            <button className="user-delete-button" onClick={togglePopup}>
              NO
            </button>
          </div>
        </div>
      </PopUpModal>

      {/* Success Popup */}
      <PopUpModal isOpen={deletePopUp} onClose={() => setDeletePopUp(false)}>
        <div className="img-block">
          <div style={{ width: "40%", height: "60%" }} className="mx-2 pt-2">
            <img
              src={logo}
              style={{ width: "100%", height: "100%" }}
              alt="logo"
            />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center py-1">
          <h6 className="popup-content mb-3">
            Form Added deleted successfully
          </h6>
          <button
            className="user-delete-button mb-3"
            onClick={() => {
              setDeletePopUp(false);
              window.location.reload();
              // Navigate to another page if needed
              // navigate("/agentManagement");
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
};

export default DatamanagementTable;
