import React, { useState } from 'react'
import Strings from '../../common/res/String';
import Images from '../../common/assets';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import axios from 'axios';
import InputField from '../../common/components/inputField/InputField';
import { Modal } from '@mui/material';

export default function Index(props) {
  const [isInvalid, setIsInvalid] = useState(false)
  // const[isError,setIsError]=useState(false)
  const [typepassword, setTypePassword] = useState("password");
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const handleForgetPasswordClick = () => {
    sessionStorage.removeItem("OTP")
    sessionStorage.setItem("view", "forget")
    props && props?.setView("forget");
  };
  //Login API Start
  const [isLoad, setIsLoad] = useState(false)

  const [iserrors, setIsError] = useState(false)
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.post(`${process.env.REACT_APP_HOST}/users/login`, postData),
    {
      onSuccess: (data) => {
        // navigator("/")
        sessionStorage.setItem("login", true);
        window.location.reload();
        // alert("Login is Successfull")
      },
      onError: (error) => {
        // alert("Login is Not Successfully")
        console.log("Mutation failed:", error);
        setIsError(true)
      },
    }
  );
  // console.log("isError",isError);

  const onSubmit = (data) => {
    console.log("LOGINDATA", data);
    let value = {
      username: data?.username,
      password: data?.password
    }
    mutate(value)
  }
  //Login API END
  console.log("iserrors", iserrors)
  return (
    <>
      <h2>{Strings.sign_in}</h2>
      <p>{Strings.welcome_back}</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-container">
          <img src={Images.emails} className="icon" />
          <Controller
            control={control}
            name="username"
            render={(field) => (
              <InputField
                // dataEntryInputField="data-entry-input-field"
                {...field}
                type="text"
                placeholder={Strings.email_address}
              />
            )}
          />


        </div>
        <div className={isError ? 'error_message_input' : 'input-container'}>
          <img src={Images.password} className="icon" />
          <Controller
            control={control}
            name="password"
            render={(field) => (
              <InputField
                // error_message=
                // dataEntryInputField="data-entry-input-field"
                {...field}
                type={typepassword}
                placeholder="Password"
              />
            )}
          />
          {/* <input
          type={typepassword}
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        /> */}
          <img
            src={`${typepassword == "password"
              ? Images.hidepasswords
              : Images.showpasswords
              }`}
            className="iconpass"
            onClick={() => {
              setTypePassword(
                `${typepassword == "password" ? "Text" : "password"}`
              );
            }}
          />
          {/* <i className="fas fa-lock icon"></i> */}
        </div>
        <div>

          {iserrors && iserrors ? (
            <p className='error_mess'>InValid Password</p>
          ) : <></>}
        </div>
        <div className="forget_label" onClick={handleForgetPasswordClick}>
          Forgot Password?
        </div>

        <button type="submit">Submit</button>
      </form>
      <Modal
        open={isInvalid}
        onClose={() => setIsInvalid(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="model_error">
          <h3>OTP is in Valid</h3>
        </div>
      </Modal>
    </>
  )
}
