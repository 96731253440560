import React, { useState, useEffect } from "react";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import addIcon from "../../assets/img/addUser.png";
import deleteIcon from "../../assets/img/delete.svg";
import searchIcon from "../../assets/img/search.png";
import Images from "../../assets";
import PopUpModal from "../popupmodal/PopUpModal";
import logo from "../../assets/img/timber_logo.png";

const UserManagementView = () => {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const itemsPerPage = 9;

  // Fetch user details
  const fetchUserDetails = async () => {
    return await axios.get(`${process.env.REACT_APP_HOST}/users`);
  };

  // Delete a user by ID
  const deleteUser = async (id) => {
    return await axios.delete(
      `${process.env.REACT_APP_HOST}/users/${id}`
    );
  };

  // Delete multiple users
  const deleteUsers = async (ids) => {
    // console.log("userID ", ids);
    return await Promise.all(
      ids?.map((id) => {
        return deleteUser(id);
      })
    );
  };
  // Fetch data
  const { data, isLoading, isError } = useQuery("data", fetchUserDetails);
  let details = data && data?.data;
  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  // Mutation to delete users
  const mutation = useMutation({
    mutationFn: deleteUsers,
    onSuccess: () => {
      setSelectedRows([]); // Clear selected rows
    },
    onError: (error) => {
      console.error("Error deleting rows:", error);
      alert("Error deleting rows");
    },
  });

  // Handle checkbox change
  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows.includes(id);
      if (isSelected) {
        return prevSelectedRows.filter((rowId) => rowId !== id);
      } else {
        return [...prevSelectedRows, id];
      }
    });
  };
  // Handle delete button click
  const handleDelete = () => {
    if (selectedRows.length === 0) {
      alert("No rows selected");
      return;
    }
    mutation.mutate(selectedRows);
  };

  // Filter data based on search term
  const filteredData = details?.filter(
    (item) =>
      item.userId?.toLowerCase().startsWith(searchTerm?.toLowerCase()) ||
      item.firstName?.toLowerCase().startsWith(searchTerm?.toLowerCase())
  );

  // Pagination logic
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  // Pagination handlers
  const isPrevDisabled = currentPage === 1;
  const isNextDisabled = currentPage === totalPages || totalPages === 0;

  const handlePrevPage = () => {
    if (!isPrevDisabled) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (!isNextDisabled) setCurrentPage(currentPage + 1);
  };
  useEffect(() => {
    // Reset to page 1 when search term changes
    setCurrentPage(1);
  }, [searchTerm]);

  return (
    <>
      <div className="headerTimeLine px-3 py-3">
        <div className="px-5 d-flex justify-content-between">
          <div className="d-flex search-block">
            <input
              type="search"
              className="table-search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="image_icon">
              <img
                src={searchIcon}
                style={{ width: "100%", height: "100%" }}
                alt="Search"
              />
            </div>
          </div>
          <div
            className="d-flex gap-2 align-items-center"
            style={{ width: "35%" }}
          >
            <div
              className="add-user-btn-block"
              onClick={() => navigate("/addUser")}
            >
              <button className="new-user-btn">NEW USER</button>
              <div className="image_icon">
                <img src={addIcon} className="img-icon" alt="Add User" />
              </div>
            </div>
            {
              selectedRows.length !== 0 ?
                <div className="add-user-btn-block">
                  <button
                    className="new-user-btn"
                    // onClick={handleDelete}
                    onClick={() => {
                      setPopupOpen(true);
                    }}
                    disabled={mutation.isLoading}
                  >
                    {mutation.isLoading ? "Deleting..." : "DELETE"}
                  </button>
                  <div className="image_icon">
                    <img src={deleteIcon} className="img-icon" alt="Delete" />
                  </div>
                </div>
                : <div
                  className="add-user-btn-block"
                  style={{
                    background: "transparent",
                    boxShadow: "none"
                  }}

                ></div>
            }
          </div>
        </div>
      </div>
      <div className="px-5 mt-3">
        <div className="view-table-block py-3 px-1">
          <table className="view-user-table">
            <thead>
              <tr>
                <th className="view-user-table-head --first-head">SELECT</th>
                <th className="view-user-table-head">USER ID</th>
                <th className="view-user-table-head">FIRST NAME</th>
                <th className="view-user-table-head">EMAIL</th>
                <th className="view-user-table-head">MOBILE NO</th>
                <th className="view-user-table-head">ROLE</th>
                <th className="view-user-table-head">STATUS</th>
                <th className="view-user-table-head --last-head">EDIT</th>
              </tr>
            </thead>
            <tbody>
              {currentItems && currentItems.length > 0 ? (
                currentItems.map((val) => (
                  <tr key={val?.id} className="view-user-table-row">
                    <td className="view-user-table-data">
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(val?.id)}
                        onChange={() => handleCheckboxChange(val?.id)}
                      />
                    </td>
                    <td className="view-user-table-data">{val?.userId}</td>
                    <td className="view-user-table-data">{`${val?.firstName || ""
                      } ${val?.lastName || ""}`}</td>
                    <td className="view-user-table-data">{val?.emailid}</td>
                    <td className="view-user-table-data">
                      {val?.mobileNumber}
                    </td>
                    <td className="view-user-table-data">{val?.role}</td>
                    <td className="view-user-table-data">{val?.status}</td>
                    <td
                      className="view-user-table-data"
                      onClick={() => navigate("/viewUser", { state: val })}
                    >
                      <img
                        src={Images?.editpencil}
                        style={{ width: "50px", height: "25px" }}
                        alt="Edit"
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">No results found</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="d-flex justify-content-between pt-2 px-1">
            <button
              onClick={handlePrevPage}
              disabled={isPrevDisabled}
              className="arrow-btn"
            >
              &lt;
            </button>
            <button
              onClick={handleNextPage}
              disabled={isNextDisabled}
              className="arrow-btn"
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
      <PopUpModal isOpen={isPopupOpen} onClose={togglePopup}>
        <div className="img-block">
          <div style={{ width: "40%", height: "60%" }} className="mx-2 pt-2">
            <img
              src={logo}
              style={{ width: "100%", height: "100%" }}
              alt="logo"
            />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center py-1">
          <h6 className="popup-content mb-3">
            Are you sure you want to delete this detail?
          </h6>
          <div className="d-flex justify-content-center gap-5 mb-3">
            <button
              className="user-delete-button"
              onClick={() => {
                handleDelete();
                setPopupOpen(false);
                setDeletePopUp(true);
              }}
            >
              OK
            </button>
            <button className="user-delete-button" onClick={togglePopup}>
              NO
            </button>
          </div>
        </div>
      </PopUpModal>

      {/* Success Popup */}
      <PopUpModal isOpen={deletePopUp} onClose={() => setDeletePopUp(false)}>
        <div className="img-block">
          <div style={{ width: "40%", height: "60%" }} className="mx-2 pt-2">
            <img
              src={logo}
              style={{ width: "100%", height: "100%" }}
              alt="logo"
            />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center py-1">
          <h6 className="popup-content mb-3">
            User has been deleted successfully
          </h6>
          <button
            className="user-delete-button mb-3"
            onClick={() => {
              setDeletePopUp(false);
              // Navigate to another page if needed
              // navigate("/agentManagement");
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
};

export default UserManagementView;
