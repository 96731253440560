import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import InputField from "../inputField/InputField";
import Strings from "../../res/String";
import PopUpModal from "../popupmodal/PopUpModal";
import Images from "../../assets";
import { useMutation } from "react-query";
import axios from "axios";

export default function NewPackingList() {
  const { control, handleSubmit, setValue, reset, watch } = useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showpassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [modalStatus, setModalStatus] = useState("");
  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };
  console.log("state", state);

  useEffect(() => {
    setValue("fileId", state?.fileId);
    setValue("date", state?.date);
    setValue("agentName", state?.agentName);
    setValue("partyName", state?.partyName);
    setValue("pkl", state?.pkl);
    setValue("cbm", state?.cbm);
    setValue("comm", state?.comm);
    setValue("load", state?.load);
    setValue("received", state?.received);
    setValue("paidAmount", state?.paidAmount);
    setValue("balanceAmount", state?.balanceAmount);
  }, []);

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.post(
        `${process.env.REACT_APP_HOST}/packinglistdetails`,
        postData
      ),
    {
      onSuccess: () => {
        console.log("user added successfully..");
        setModalStatus("Packing List has been added successfully");
        togglePopup();
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  const upData = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/packinglistdetails/${state && state.id
        }`,
        postData
      ),
    {
      onSuccess: () => {
        setModalStatus("Packing List has been updated successfully");
        togglePopup();
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  const onSubmit = (data) => {
    console.log("data", data);
    // mutate(data)
    if (state && state?.id) {
      upData.mutate(data);
      console.log("upData", data);
    } else if (data?.fileId !== undefined) {
      // data?.userForm.map((ele) => {
      mutate(data);
      console.log("mutate", data);
      // });
    } else {
      alert("Kindly File a Data")
    }
  };
  const togglePassword = () => {
    setShowPassword(!showpassword);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="px-3 py-4">
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.fieldId}
            </label>
            <div className="data-entry-input --add-user-input">
              <Controller
                control={control}
                name="fileId"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="text"
                    placeholder="F02"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.date}
            </label>
            <div className="data-entry-input --add-user-input">
              <Controller
                control={control}
                name="date"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="date"
                    placeholder="DD/MM/YYYY"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.agentName}
            </label>
            <div className="data-entry-input --add-user-input">
              <Controller
                control={control}
                name="agentName"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="text"
                    placeholder="KOMMI"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.partyName}
            </label>
            <div className="data-entry-input --add-user-input">
              <Controller
                control={control}
                name="partyName"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="text"
                    placeholder="ARW"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.pkl}
            </label>
            <div className="data-entry-input --add-user-input">
              <Controller
                control={control}
                name="pkl"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="12"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.cmb}
            </label>
            <div className="data-entry-input --add-user-input">
              <Controller
                control={control}
                name="cbm"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="text"
                    placeholder="13.790"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.comm}
            </label>
            <div className="addUserInputBlock">
              <Controller
                control={control}
                name="comm"
                render={(field) => (
                  <InputField
                    addUserPassword="addUserPassword"
                    {...field}
                    type="text"
                    placeholder="14745.00"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.load}
            </label>
            <div className="addUserInputBlock">
              <Controller
                control={control}
                name="load"
                render={(field) => (
                  <InputField
                    addUserPassword="addUserPassword"
                    {...field}
                    type="text"
                    placeholder="100000.00"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.received}
            </label>
            <div className="addUserInputBlock">
              <Controller
                control={control}
                name="received"
                render={(field) => (
                  <InputField
                    addUserPassword="addUserPassword"
                    {...field}
                    type="text"
                    placeholder="85255.00"
                  />
                )}
              />
            </div>
          </div>
          {/* <div className="dataEntryInputContainer py-2"> */}
          <div className="data-entry-input-block">
            <label className="data-entry-label --add-user-label">
              {Strings.paidAmount}
            </label>
            <div className="addUserInputBlock">
              <Controller
                control={control}
                name="paidAmount"
                render={(field) => (
                  <InputField
                    addUserPassword="addUserPassword"
                    {...field}
                    type="text"
                    placeholder="85255.00"
                  />
                )}
              />
            </div>
          </div>
          {/* </div> */}

          <div />
          <div className="dataEntryInputContainer py-3">
            <div className="data-entry-input-block">
              <label className="data-entry-label --add-user-label">
                {Strings.BalanceAmount}
              </label>
              <div className="addUserInputBlock">
                <Controller
                  control={control}
                  name="balanceAmount"
                  render={(field) => (
                    <InputField
                      addUserPassword="addUserPassword"
                      {...field}
                      type="text"
                      placeholder="85255.00"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="addUserBtnContainer text-center py-3">
          <button className="add-user-submit-btn">Submit</button>
        </div>
      </form>

      <PopUpModal isOpen={isPopupOpen} onClose={togglePopup}>
        <div className="img-block">
          <div style={{ width: "40%", height: "60%" }} className="mx-2 pt-2">
            <img
              src={Images.logo}
              style={{ width: "100%", height: "100%" }}
              alt=""
            />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center py-2">
          <h6 className="popup-content mb-3">{modalStatus}</h6>
          <button
            className="user-add-button mt-2 mb-2"
            onClick={() => {
              navigate("/agentManagement");
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
}
// {
//   "id": 9,
//   "fileId": null,
//   "agentName": "KOMMI",
//   "pkl": "100",
//   "comm": "22222",
//   "received": "222222",
//   "balanceAmount": null,
//   "date": "2024-08-23",
//   "partyName": "ARW",
//   "cbm": null,
//   "load": "222222",
//   "paidAmount": "2222222",
//   "status": "inactive"
// }
