import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Strings from "../../res/String";
import InputField from "../inputField/InputField";
import Model from "../model/Model";
import Images from "../../assets";
import PopUpModal from "../popupmodal/PopUpModal";
import logo from "../../assets/img/timber_logo.png";
import axios from "axios";
import deleteIcon from "../../assets/img/delete.svg";
import searchIcon from "../../assets/img/search.png";
import addIcon from "../../assets/img/addUser.png";
import * as XLSX from "xlsx";
// import Images from "../../assets";

import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

export default function AgentManagementCom() {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [statusValue, setStatusValue] = useState();
  const [agentValue, setAgentValue] = useState();
  const [deleteSuccessfully, setDeleteSuccessfully] = useState();
  const [deleteIndex, setDeleteIndex] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  console.log("deletePopUp", deletePopUp);
  console.log("isPopupOpen", isPopupOpen);

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const itemsPerPage = 9;

  // Fetch user details
  const fetchUserDetails = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/packinglistdetails/all`
    );
  };

  // console.log('fetchUserDetails',fetchUserDetails);

  // Delete a user by ID
  // const deleteUser = async (id) => {
  //   return await axios.delete(
  //     `${process.env.REACT_APP_HOST}/users/${id}`
  //   );
  // };
  const { data, isLoading, isError, refetch } = useQuery(
    "data",
    fetchUserDetails
  );
  let details = data && data?.data;

  console.log("details", details);
  const HandlerDelete = async (itemId) => {
    // const confirmDelete = window.confirm('Are you sure you want to delete?');
    // const confirmDelete = window.confirm("Are you sure you want to delete?");
    // if (deleteIndex) {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST}/packinglistdetails/bulk`,
        {
          data: deleteIndex,
        }
      );
      console.log("response");

      refetch();

      document.getElementById("myCheckbox").checked = false;
      setDeletePopUp(!deletePopUp);
      setPopupOpen(false);
      return response.data;
    } catch (error) {
      console.log("Error", error);
    }
  };

  // const agentNameList = []
  // agentNameList = details?.filter((item)=>{
  //   console.log('items',item);

  //   return (item.agentName === item.agentName)
  // })

  // console.log('agentNameList',agentNameList)

  let actionFilterValue = [];
  actionFilterValue = details?.filter((item) => {
    if (statusValue && agentValue) {
      return (
        item.status === statusValue && agentValue?.includes(item.agentName)
      );
    } else if (statusValue) {
      return item.status === statusValue;
    } else if (agentValue) {
      return item.agentName === agentValue;
    }

    // (item.agentName === agentValue && statusValue?.includes(item.status))) ||
    // item.status === statusValue ||
    // item.agentName === agentValue
  });

  console.log("actionFilterValue", actionFilterValue);

  // Filter data based on search term
  const filteredData = details?.filter((item) =>
    item.fileId?.toLowerCase().startsWith(searchTerm?.toLowerCase())
  );

  console.log("filteredData", filteredData);
  // Pagination logic
  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  // console.log('currentItems',currentItems);

  // Pagination handlers
  const isPrevDisabled = currentPage === 1;
  const isNextDisabled = currentPage === totalPages || totalPages === 0;

  const handlePrevPage = () => {
    if (!isPrevDisabled) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (!isNextDisabled) setCurrentPage(currentPage + 1);
  };
  useEffect(() => {
    // Reset to page 1 when search term changes
    setCurrentPage(1);
  }, [searchTerm]);
  // Download Start
  const filterdataFile =
    details &&
    details.filter((item) => {
      if (deleteIndex.length !== 0) {
        deleteIndex.includes(item.id);
      }
    });
  console.log("filteredData", filteredData, deleteIndex);
  const downloadExcel = () => {
    // Your data array
    const data = [
      {
        id: 4,
        fileId: "12345",
        agentName: "Agent A",
        pkl: "PKL001",
        comm: "Comment",
        received: "Yes",
        balanceAmount: 5000,
        date: "2024-08-22",
        partyName: "Party A",
        cbm: "CBM001",
        load: "Load001",
        paidAmount: "50025",
        status: "active",
      },
      // ... other data objects
    ];

    // Define headers
    const headers = [
      "S.no",
      "FILE ID",
      "DATE",
      "AGENT",
      "PARTY NAME",
      "PKL",
      "CBM",
      "COMM",
      "LOAD",
      "RECEIVED",
      "PAID",
      "BALANCE",
      "STATUS",
    ];

    // Map data to match the headers order
    const formattedData =
      deleteIndex.length !== 0
        ? filterdataFile.map((item, index) => ({
          "S.no": index + 1,
          "FILE ID": item.fileId || "",
          DATE: item.date || "",
          AGENT: item.agentName || "",
          "PARTY NAME": item.partyName || "",
          PKL: item.pkl || "",
          CBM: item.cbm || "",
          COMM: item.comm || "",
          LOAD: item.load || "",
          RECEIVED: item.received || "",
          PAID: item.paidAmount || "",
          BALANCE: item.balanceAmount || "",
          STATUS: item.status || "",
        }))
        : actionFilterValue?.length !== 0
          ? actionFilterValue &&
          actionFilterValue.map((item, index) => ({
            "S.no": index + 1,
            "FILE ID": item.fileId || "",
            DATE: item.date || "",
            AGENT: item.agentName || "",
            "PARTY NAME": item.partyName || "",
            PKL: item.pkl || "",
            CBM: item.cbm || "",
            COMM: item.comm || "",
            LOAD: item.load || "",
            RECEIVED: item.received || "",
            PAID: item.paidAmount || "",
            BALANCE: item.balanceAmount || "",
            STATUS: item.status || "",
          }))
          : filteredData
            ? filteredData &&
            filteredData.map((item, index) => ({
              "S.no": index + 1,
              "FILE ID": item.fileId || "",
              DATE: item.date || "",
              AGENT: item.agentName || "",
              "PARTY NAME": item.partyName || "",
              PKL: item.pkl || "",
              CBM: item.cbm || "",
              COMM: item.comm || "",
              LOAD: item.load || "",
              RECEIVED: item.received || "",
              PAID: item.paidAmount || "",
              BALANCE: item.balanceAmount || "",
              STATUS: item.status || "",
            }))
            : details;
    details &&
      details.map((item, index) => ({
        "S.no": index + 1,
        "FILE ID": item.fileId || "",
        DATE: item.date || "",
        AGENT: item.agentName || "",
        "PARTY NAME": item.partyName || "",
        PKL: item.pkl || "",
        CBM: item.cbm || "",
        COMM: item.comm || "",
        LOAD: item.load || "",
        RECEIVED: item.received || "",
        PAID: item.paidAmount || "",
        BALANCE: item.balanceAmount || "",
        STATUS: item.status || "",
      }));

    // Create a new workbook and a worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(formattedData, { header: headers });

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate Excel file and trigger download
    XLSX.writeFile(wb, "Data.xlsx");
  };
  // Download End
  let totalCbm = 0;
  let totalComm = 0;
  let totalLoad = 0;
  let totalReceived = 0;
  let totalPaid = 0;
  let totalBalance = 0;
  console.log(
    "deleteIndex",
    deleteIndex,
    details,
    deleteIndex.length !== 0,
    details && details.filter((item) => deleteIndex.includes(item.id))
  );
  return (
    <>
      <div className="headerTimeLine px-3 py-3">
        <div className="px-5 d-flex justify-content-between">
          <div className="d-flex search-block">
            <input
              type="search"
              className="table-search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="image_icon">
              <img
                src={searchIcon}
                style={{ width: "100%", height: "100%" }}
                alt="Search"
              />
            </div>
          </div>
          <div
            className="d-flex gap-2 align-items-center"
            style={{ width: "50%" }}
          >
            <div className="add-user-btn-block">
              <select
                className="new-user-btn text-center"
                onChange={(event) => {
                  setAgentValue(event.target.value);
                }}
              >
                <option value="">Agent</option>
                {[...new Set(details?.map((data) => data.agentName))].map(
                  (agentName, index) => (
                    <option key={index} value={agentName}>
                      {agentName}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className="add-user-btn-block">
              <select
                className="new-user-btn text-center"
                onChange={(event) => {
                  setStatusValue(event.target.value);
                }}
              >
                <option value="">STATUS</option>
                <option value="active">ACTIVE</option>
                <option value="inactive">INACTIVE</option>
              </select>
            </div>
            <div
              className="add-user-btn-block"
              onClick={() => navigate("/newPackingList")}
            >
              <button className="new-user-btn">NEW PKL</button>
            </div>
            <div className="add-user-btn-block" onClick={downloadExcel}>
              <button className="new-user-btn">DOWNLOAD</button>
            </div>
            {
              deleteIndex.length !== 0 ?
                <div className="add-user-btn-block">
                  <button
                    className="new-user-btn"
                    onClick={() => {
                      setPopupOpen(true);
                    }}
                  // disabled={mutation.isLoading}
                  >
                    DELETE
                    {/* {mutation.isLoading ? "Deleting..." : "DELETE"} */}
                  </button>
                  <div className="image_icon">
                    <img src={deleteIcon} className="img-icon" alt="Delete" />
                  </div>
                </div>
                : <></>
            }
          </div>
        </div>
      </div>
      <div className="px-5 mt-3">
        <div className="view-table-block  px-1">
          <table className="view-user-table">
            <thead>
              <tr style={{ position: "sticky", top: "0px" }}>
                <th className="agent-view-user-table-head --first-head">
                  SELECT
                </th>
                <th className="agent-view-user-table-head">S.no</th>
                <th className="agent-view-user-table-head">FILE ID</th>
                <th className="agent-view-user-table-head">DATE</th>
                <th className="agent-view-user-table-head">AGENT</th>
                <th className="agent-view-user-table-head">PARTY NAME</th>
                <th className="agent-view-user-table-head">PKL</th>
                <th className="agent-view-user-table-head">CBM</th>
                <th className="agent-view-user-table-head">COMM</th>
                <th className="agent-view-user-table-head">LOAD</th>
                <th className="agent-view-user-table-head">RECEIVED</th>
                <th className="agent-view-user-table-head">PAID</th>
                <th className="agent-view-user-table-head">BALANCE</th>
                <th className="agent-view-user-table-head">STATUS</th>
                <th className="agent-view-user-table-head --last-head">EDIT</th>
              </tr>
            </thead>
            <tbody>
              {actionFilterValue?.length !== 0
                ? actionFilterValue &&
                actionFilterValue?.map((val, i) => {
                  console.log("val?.paid", val?.paid);
                  totalCbm = Number(val?.cbm) + totalCbm;
                  totalBalance = Number(val?.balanceAmount) + totalBalance;
                  totalComm = Number(val?.comm) + totalComm;
                  totalLoad = Number(val?.load) + totalLoad;
                  totalReceived = Number(val?.received) + totalReceived;
                  totalPaid = Number(val?.paidAmount) + totalPaid;
                  return (
                    <tr key={val?.id} className="view-user-table-row">
                      <td className="view-user-table-data">
                        <input
                          id="myCheckbox"
                          type="checkbox"
                          // checked={selectedRows.includes(val?.id)}
                          onChange={() =>
                            setDeleteIndex([...deleteIndex, val.id])
                          }
                        />
                      </td>
                      <td className="agent-view-user-table-data">{i + 1}</td>
                      <td className="agent-view-user-table-data">
                        {val?.fileId}
                      </td>
                      <td className="agent-view-user-table-data">
                        {val?.date}
                      </td>
                      <td className="agent-view-user-table-data">
                        {val?.agentName}
                      </td>
                      <td className="agent-view-user-table-data">
                        {val?.partyName}
                      </td>
                      <td className="agent-view-user-table-data">
                        {val?.pkl}
                      </td>
                      <td className="agent-view-user-table-data">
                        {val?.cbm}
                      </td>
                      <td className="agent-view-user-table-data">
                        {val?.comm}
                      </td>
                      <td className="agent-view-user-table-data">
                        {val?.load}
                      </td>
                      <td className="agent-view-user-table-data">
                        {val?.received}
                      </td>
                      <td className="agent-view-user-table-data">
                        {val?.paidAmount}
                      </td>
                      <td className="agent-view-user-table-data">
                        {val?.balanceAmount}
                      </td>
                      <td className="agent-view-user-table-data">
                        {val?.status}
                      </td>
                      <td
                        className="agent-view-user-table-data"
                        onClick={() =>
                          navigate("/newPackingList", { state: val })
                        }
                      >
                        <img
                          src={Images?.editpencil}
                          style={{ width: "50px", height: "25px" }}
                          alt="Edit"
                        />
                      </td>
                    </tr>
                  );
                })
                : filteredData
                  ? filteredData &&
                  filteredData?.map((val, i) => {
                    console.log("val?.paid", val);
                    totalCbm = Number(val?.cbm) + totalCbm;
                    totalBalance = Number(val?.balanceAmount) + totalBalance;
                    totalComm = Number(val?.comm) + totalComm;
                    totalLoad = Number(val?.load) + totalLoad;
                    totalReceived = Number(val?.received) + totalReceived;
                    totalPaid = Number(val?.paidAmount) + totalPaid;
                    return (
                      <tr key={val?.id} className="view-user-table-row">
                        <td className="view-user-table-data">
                          <input
                            type="checkbox"
                            id="myCheckbox"
                            // checked={selectedRows.includes(val?.id)}
                            onChange={() =>
                              setDeleteIndex([...deleteIndex, val.id])
                            }
                          />
                        </td>
                        <td className="agent-view-user-table-data">{i + 1}</td>
                        <td className="agent-view-user-table-data">
                          {val?.fileId}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.date}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.agentName}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.partyName}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.pkl}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.cbm}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.comm}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.load}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.received}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.paidAmount}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.balanceAmount}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.status}
                        </td>
                        <td
                          className="agent-view-user-table-data"
                          onClick={() =>
                            navigate("/newPackingList", { state: val })
                          }
                        >
                          <img
                            src={Images?.editpencil}
                            style={{ width: "50px", height: "25px" }}
                            alt="Edit"
                          />
                        </td>
                      </tr>
                    );
                  })
                  : details &&
                  details?.map((val, i) => {
                    console.log("val?.paid", val?.paid);
                    totalCbm = Number(val?.cbm) + totalCbm;
                    totalBalance = Number(val?.balanceAmount) + totalBalance;
                    totalComm = Number(val?.comm) + totalComm;
                    totalLoad = Number(val?.load) + totalLoad;
                    totalReceived = Number(val?.received) + totalReceived;
                    totalPaid = Number(val?.paidAmount) + totalPaid;
                    return (
                      <tr key={val?.id} className="view-user-table-row">
                        <td className="view-user-table-data">
                          <input
                            id="myCheckbox"
                            type="checkbox"
                            // checked={selectedRows.includes(val?.id)}
                            onChange={() =>
                              setDeleteIndex([...deleteIndex, val.id])
                            }
                          />
                        </td>
                        <td className="agent-view-user-table-data">{i + 1}</td>
                        <td className="agent-view-user-table-data">
                          {val?.fileId}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.date}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.agentName}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.partyName}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.pkl}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.cbm}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.comm}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.load}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.received}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.paidAmount}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.balanceAmount}
                        </td>
                        <td className="agent-view-user-table-data">
                          {val?.status}
                        </td>
                        <td
                          className="agent-view-user-table-data"
                          onClick={() =>
                            navigate("/newPackingList", { state: val })
                          }
                        >
                          <img
                            src={Images?.editpencil}
                            style={{ width: "50px", height: "25px" }}
                            alt="Edit"
                          />
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          {/* <div className="d-flex justify-content-between pt-2 px-1">
            <button
              onClick={handlePrevPage}
              disabled={isPrevDisabled}
              className="arrow-btn"
            >
              &lt;
            </button>
            <button
              onClick={handleNextPage}
              disabled={isNextDisabled}
              className="arrow-btn"
            >
              &gt;
            </button>
          </div> */}
        </div>
        <div
          className="px-2 "
          style={{
            height: "40px",
            backgroundColor: "#74512d",
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          {/* <div className="row" style={{height:"45px",backgroundColor:""}}>
            <div className="col-4 d-flex justify-content-end align-items-center" style={{paddingRight:"42px"}}>
            Total
            </div>
            <div className="col-2 bg-danger d-flex justify-content-end align-items-center" style={{paddingRight:""}}>
            Total
            </div>
        </div> */}
          <div className="d-flex align-items-center pt-2">
            <div
              className="agent-view-totalAmt text-end pe-4"
              style={{ width: "382px", backgroundColor: "" }}
            >
              {" "}
              Total
            </div>
            <div
              className="agent-view-totalAmt text-end pe-3"
              style={{ width: "224px", backgroundColor: "" }}
            >
              {" "}
              {totalCbm}
            </div>
            <div
              className="agent-view-totalAmt text-center"
              style={{ width: "78px", backgroundColor: "" }}
            >
              {" "}
              {totalComm.toFixed(2)}
            </div>
            <div
              className="agent-view-totalAmt text-center"
              style={{ width: "90px", backgroundColor: "" }}
            >
              {totalLoad.toFixed(2)}
            </div>
            <div
              className="agent-view-totalAmt text-center"
              style={{ width: "90px", backgroundColor: "" }}
            >
              {totalReceived.toFixed(2)}
            </div>
            <div
              className="agent-view-totalAmt text-center"
              style={{ width: "75px", backgroundColor: "" }}
            >
              {totalPaid}
            </div>
            <div
              className="agent-view-totalAmt text-center"
              style={{ width: "80px", backgroundColor: "" }}
            >
              {" "}
              {totalBalance.toFixed(2)}
            </div>
          </div>
        </div>
      </div>
      <PopUpModal isOpen={isPopupOpen} onClose={togglePopup}>
        <div className="img-block">
          <div style={{ width: "40%", height: "60%" }} className="mx-2 pt-2">
            <img src={logo} style={{ width: "100%", height: "100%" }} />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center py-1">
          <h6 className="popup-content mb-3">
            Are you sure to delete the user detail?
          </h6>
          <div className="d-flex justify-content-center gap-5 mb-3">
            <button
              className="user-delete-button"
              onClick={() => HandlerDelete()}
            >
              OK
            </button>
            <button
              className="user-delete-button"
              onClick={() => {
                setPopupOpen(false);
              }}
            >
              NO
            </button>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal isOpen={deletePopUp}>
        <div className="img-block">
          <div style={{ width: "40%", height: "60%" }} className="mx-2 pt-2">
            <img src={logo} style={{ width: "100%", height: "100%" }} />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center py-1">
          <h6 className="popup-content mb-3">
            User has been deleted successfully
          </h6>
          <button
            className="user-delete-button mb-3"
            onClick={() => {
              // setDeletePopUp(false)
              // navigate("/agentManagement")
              setDeletePopUp(false);
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
}
